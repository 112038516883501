import { Box, Divider, Flex, Stack, useTheme } from '@chakra-ui/react';
import { Position, PositionTypes } from '@/types';

import { PLATFORMS } from '@/constants';
import React from 'react';
import Text from '@/components/shared/Text';
import TokenLogo from '../shared/TokenLogo';
import { calculatePositionStats } from '@/helpers/positionHelpers';
import { findDefaultPlatformByTrigger } from '@/helpers/findHelpers';
import { useRouter } from 'next/router';

interface Props {
  columnWidths: number[];
  lastItem: boolean;
  position: Position;
  positionType: PositionTypes;
}

const DashboardPositionRow: React.FC<Props> = ({ columnWidths, lastItem, position, positionType }: Props) => {
  const theme = useTheme();
  const router = useRouter();

  const { marketId, market } = position;
  const { trigger, underlyingName, underlyingSymbol } = market;
  const { errorColor, successColor } = theme.utilityColors;
  const isSupplyPositionType = positionType === PositionTypes.Supply;

  const {
    formattedBorrowRate,
    formattedSupplyRate,
    formattedSupplyTokenBalance,
    formattedBorrowTokenBalance,
    formattedUSDSupplyBalance,
    formattedUSDBorrowBalance,
  } = calculatePositionStats(position, market);

  const relevantTokenBalance = isSupplyPositionType ? formattedSupplyTokenBalance : formattedBorrowTokenBalance;
  const relevantUsdBalance = isSupplyPositionType ? formattedUSDSupplyBalance : formattedUSDBorrowBalance;

  const platform = findDefaultPlatformByTrigger(PLATFORMS, trigger);

  const onClick = () => {
    if (trigger == null) {
      isSupplyPositionType
        ? router.push(`/funds?market=${marketId}&tab=0`)
        : router.push(`/borrow?market=${marketId}&tab=0`);
    } else {
      isSupplyPositionType
        ? router.push(`/provide?market=${marketId}&tab=0`)
        : router.push(`/protected-borrow?market=${marketId}&tab=0`);
    }
  };

  return (
    <>
      <Box py={5} px={6} _hover={{ backgroundColor: 'gray.50' }} cursor="pointer" onClick={onClick}>
        <Flex justifyContent="flex-start" alignItems="center">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            flex={columnWidths[0]}
            spacing={{ base: 4, md: 0 }}
          >
            <Flex
              flexDirection={{ base: 'column', sm: 'row' }}
              alignItems={{ base: 'flex-start', sm: 'center' }}
              flex={1}
            >
              <Flex alignItems="center" flex={1}>
                <TokenLogo height={8} boxSize={8} name={underlyingName} symbol={underlyingSymbol} />
                <Box ml={4}>
                  <Text flex={1} fontSize="md" fontWeight="600">
                    {underlyingSymbol}
                  </Text>
                  {trigger && platform && (
                    <Text fontSize="sm" color={{ base: 'gray.500', sm: 'gray.700' }}>
                      {platform.name}{' '}
                      <Text color="gray.500" as="span" display={{ base: 'none', sm: 'inline' }}>
                        {trigger.name}
                      </Text>
                    </Text>
                  )}
                </Box>
              </Flex>
            </Flex>
          </Stack>

          <Text
            textAlign="right"
            flex={columnWidths[1]}
            fontSize="md"
            fontWeight="600"
            color={positionType === PositionTypes.Supply ? successColor : errorColor}
          >
            {positionType === PositionTypes.Supply ? formattedSupplyRate : formattedBorrowRate}
          </Text>

          <Box textAlign="right" flex={columnWidths[2]}>
            <Text fontSize="md" fontWeight="600">
              {relevantUsdBalance}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {relevantTokenBalance} {underlyingSymbol}
            </Text>
          </Box>
        </Flex>
      </Box>
      {!lastItem && <Divider />}
    </>
  );
};
export default DashboardPositionRow;

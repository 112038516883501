import { Flex, Stack, useTheme } from '@chakra-ui/react';
import useFetchMarketData, { SubgraphData } from '@/hooks/useFetchMarketData';

import CommonModals from '@/components/modals/CommonModals';
import DashboardDetails from '@/components/dashboard/DashboardDetails';
import DashboardSummary from '@/components/dashboard/DashboardSummary';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import PageHeader from '@/components/shared/PageHeader';
import React from 'react';
import TheGraphLoadingTag from '@/components/shared/TheGraphLoadingTag';
import { preloadSubgraphData } from '@/helpers/preloadDataHelpers';
import useFetchAccountData from '@/hooks/useFetchAccountData';
import useFetchInvestData from '@/hooks/useFetchInvestAccountData';
import useFetchInvestMarkets from '@/hooks/useFetchInvestMarkets';

interface Props {
  initialSubgraphData: SubgraphData;
}

export const dashboardSubnav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/activity', label: 'Recent Activity' },
];

const Dashboard: React.FC<Props> = ({ initialSubgraphData }: Props) => {
  const theme = useTheme();

  const { account: cozyAccount, loadingAccountData, errorLoadingAccountData } = useFetchAccountData();
  const { comptroller, markets } = useFetchMarketData(initialSubgraphData);
  const { enhancedMarkets } = useFetchInvestMarkets(markets);

  const { account } = useFetchInvestData(cozyAccount, enhancedMarkets);

  return (
    <>
      <Head>
        <title>Cozy Dashboard</title>
      </Head>

      <CommonModals account={account} comptroller={comptroller} markets={markets} />

      <TheGraphLoadingTag errorLoading={errorLoadingAccountData} isLoading={loadingAccountData} />

      <Flex flexDir="column" alignItems="center" mb={16}>
        <PageHeader
          title="Cozy Dashboard"
          description="See an overview of your Cozy balances and activities"
          subnav={dashboardSubnav}
        />

        <Stack
          direction={{ base: 'column', md: 'row' }}
          maxW={theme.maxPageWidth}
          width="100%"
          px={{ base: 4, md: 6, xl: 8 }}
          spacing={4}
        >
          <DashboardSummary account={account} loadingAccountData={loadingAccountData} />
          <DashboardDetails account={account} loadingAccountData={loadingAccountData} />
        </Stack>
      </Flex>
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return preloadSubgraphData();
};

export default Dashboard;

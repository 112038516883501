import { Account, MarketTypes, PositionTypes } from '@/types';
import { Box, Stack } from '@chakra-ui/react';
import {
  calculateBorrowInterestRate,
  calculateInvestInterestRate,
  calculateSupplyInterestRate,
  calculateTotalBorrowBalance,
  calculateTotalCozyAssets,
  calculateTotalInvestBalance,
  calculateTotalSupplyBalance,
} from '@/helpers/accountHelpers';
import { findBorrowPositions, findSupplyPositions } from '@/helpers/findHelpers';

import DetailSection from './DashboardDetailSection';
import React from 'react';
import { formatUsdAmount } from '@/helpers/formatHelpers';

interface Props {
  account: Account;
  loadingAccountData: boolean;
}

const DashboardDetails: React.FC<Props> = ({ account, loadingAccountData }: Props) => {
  const totalCozyAssets = calculateTotalCozyAssets(account);
  const totalBorrowBalance = calculateTotalBorrowBalance(account);

  const savingsInterestPercentage = calculateSupplyInterestRate(account, MarketTypes.NonProtectionMarket);
  const supplyInterestPercentage = calculateSupplyInterestRate(account, MarketTypes.ProtectionMarket);

  const savingsPositions = findSupplyPositions(account, MarketTypes.NonProtectionMarket);
  const supplyPositions = findSupplyPositions(account, MarketTypes.ProtectionMarket);

  const totalNonProtectionMarketBalance = calculateTotalSupplyBalance(account, MarketTypes.NonProtectionMarket);
  const totalProtectionMarketBalance = calculateTotalSupplyBalance(account, MarketTypes.ProtectionMarket);

  const borrowInterestPercentage = calculateBorrowInterestRate(account, MarketTypes.NonProtectionMarket);
  const protectedBorrowInterestPercentage = calculateBorrowInterestRate(account, MarketTypes.ProtectionMarket);

  const borrowPositions = findBorrowPositions(account, MarketTypes.NonProtectionMarket);
  const protectedBorrowPositions = findBorrowPositions(account, MarketTypes.ProtectionMarket);

  const totalNonProtectionMarketBorrowBalance = calculateTotalBorrowBalance(account, MarketTypes.NonProtectionMarket);
  const totalProtectionMarketBorrowBalance = calculateTotalBorrowBalance(account, MarketTypes.ProtectionMarket);

  const { investBalance } = calculateTotalInvestBalance(account);
  const { investInterestRate } = calculateInvestInterestRate(account, investBalance, 0);

  const supplyRows = [
    {
      name: 'Deposited Funds',
      value: totalNonProtectionMarketBalance,
      interestPercentage: savingsInterestPercentage,
      positions: savingsPositions,
      positionType: PositionTypes.Supply,
    },
    {
      name: 'Supplied as Protection',
      value: totalProtectionMarketBalance,
      interestPercentage: supplyInterestPercentage,
      positions: supplyPositions,
      positionType: PositionTypes.Supply,
    },
    {
      name: 'Protected Investing',
      value: investBalance,
      interestPercentage: investInterestRate,
      positions: [],
      positionType: PositionTypes.Invest,
    },
  ];

  const borrowRows = [
    {
      name: 'Borrowed Funds',
      value: totalNonProtectionMarketBorrowBalance,
      interestPercentage: borrowInterestPercentage,
      positions: borrowPositions,
      positionType: PositionTypes.Borrow,
    },
    {
      name: 'Protected Borrows',
      value: totalProtectionMarketBorrowBalance,
      interestPercentage: protectedBorrowInterestPercentage,
      positions: protectedBorrowPositions,
      positionType: PositionTypes.Borrow,
    },
  ];

  return (
    <Box flex={1}>
      <Stack flex={1} spacing={6} ml={{ base: 0, md: 4 }}>
        <DetailSection
          heading="Your Funds"
          headingValue={formatUsdAmount(totalCozyAssets)}
          loading={loadingAccountData}
          positionType={PositionTypes.Supply}
          rows={supplyRows}
        />

        <DetailSection
          heading="Your Debt"
          headingValue={formatUsdAmount(totalBorrowBalance)}
          loading={loadingAccountData}
          positionType={PositionTypes.Borrow}
          rows={borrowRows}
        />
      </Stack>
    </Box>
  );
};
export default DashboardDetails;

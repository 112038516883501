import { Account, ButtonType } from '@/types';
import { Box, Flex, Progress, Stack, useTheme } from '@chakra-ui/react';
import { calculateNetInterestRate, calculateTotalCozyBalance } from '@/helpers/accountHelpers';
import { formatNumber, formatUsdAmount } from '@/helpers/formatHelpers';

import Button from '@/components/shared/Button';
import Container from '@/components/shared/Container';
import Divider from '@/components/shared/Divider';
import Heading from '../shared/Heading';
import React from 'react';
import Skeleton from '../shared/Skeleton';
import Stat from '@/components/shared/Stat';
import Text from '@/components/shared/Text';
import { calculateBorrowLimitStats } from '@/helpers/borrowLimitHelpers';
import { chooseBorrowingPowerBarColor } from '@/helpers/borrowingPowerHelpers';
import { useRouter } from 'next/router';

interface Props {
  account: Account;
  loadingAccountData: boolean;
}

const DashboardSummary: React.FC<Props> = ({ account, loadingAccountData }: Props) => {
  const theme = useTheme();
  const router = useRouter();

  const currentNetInterestRate = calculateNetInterestRate(account);
  const {
    borrowLimitUsdTotal,
    percentBorrowed,
    usdBorrowed,
    borrowLimitUsdLeft,
    isInLiquidation,
    isNearLiquidation,
  } = calculateBorrowLimitStats(account);

  const totalCozyBalance = calculateTotalCozyBalance(account);

  const currentNetInterestRatePercentage = currentNetInterestRate * 100;
  const colorScheme = chooseBorrowingPowerBarColor(isInLiquidation, isNearLiquidation);

  return (
    <Box>
      <Container minW="18em" boxShadow={{ base: null, md: 'lg' }} p={0}>
        <Stack spacing={8} py={6}>
          <Box px={6}>
            <Text fontWeight="600" color="gray.500" fontSize="sm" mb={2}>
              Your Cozy Balace
            </Text>
            <Skeleton isLoaded={!loadingAccountData}>
              <Heading fontSize="4xl">{formatUsdAmount(totalCozyBalance)}</Heading>
            </Skeleton>
          </Box>

          <Stat
            color={currentNetInterestRate > 0 ? theme.utilityColors.successColor : theme.utilityColors.errorColor}
            loading={loadingAccountData}
            label="Current Net Interest"
            number={formatNumber(currentNetInterestRatePercentage)}
            px={6}
            unit="%"
          />

          <Divider />

          <Box px={6}>
            <Flex alignItems="flex-start">
              <Box flex={1}>
                <Text fontWeight="600" color="gray.500" fontSize="sm" mb={2}>
                  Borrowing Power
                </Text>
                <Skeleton isLoaded={!loadingAccountData}>
                  <Heading fontSize="2xl">{formatUsdAmount(borrowLimitUsdTotal)}</Heading>
                </Skeleton>
              </Box>

              <Button
                appButtonType={ButtonType.Ghost}
                color="purple.500"
                fontSize="sm"
                fontWeight={600}
                mt="-12px"
                onClick={() => router.push('/funds')}
              >
                Increase
              </Button>
            </Flex>
            <Progress
              size="sm"
              borderRadius="100px"
              maxW="24em"
              value={percentBorrowed * 100}
              mt={2}
              width="100%"
              colorScheme={colorScheme}
            />

            <Flex justifyContent="space-between" mt={3}>
              <Skeleton isLoaded={!loadingAccountData}>
                <Text fontSize="sm" fontWeight="600" color="gray.500">
                  {formatUsdAmount(usdBorrowed)} used
                </Text>
              </Skeleton>
              <Skeleton isLoaded={!loadingAccountData}>
                <Text fontSize="sm" fontWeight="600" color="gray.500">
                  {formatUsdAmount(borrowLimitUsdLeft)} available
                </Text>
              </Skeleton>
            </Flex>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
export default DashboardSummary;

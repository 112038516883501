import { Flex } from '@chakra-ui/react';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  columnWidths: number[];
}

const DashboardSectionHeader: React.FC<Props> = ({ columnWidths }: Props) => {
  return (
    <Flex
      backgroundColor="gray.50"
      borderTopColor="gray.100"
      borderTopWidth="1px"
      color="gray.300"
      display={{ base: 'none', sm: 'flex' }}
      fontSize="sm"
      fontWeight="600"
      px={6}
      py={2}
    >
      <Text flex={columnWidths[0]}>Account</Text>
      <Text textAlign="right" flex={columnWidths[1]}>
        Interest
      </Text>
      <Text textAlign="right" flex={columnWidths[2]}>
        Total
      </Text>
    </Flex>
  );
};
export default DashboardSectionHeader;

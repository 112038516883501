import DashboardDetailRow, { DashboardDetailRowProps } from './DashboardDetailRow';
import { Flex, useBreakpointValue } from '@chakra-ui/react';

import Container from '@/components/shared/Container';
import DashboardSectionHeader from './DashboardSectionHeader';
import Heading from '../shared/Heading';
import { PositionTypes } from '@/types';
import React from 'react';
import Skeleton from '../shared/Skeleton';
import Text from '@/components/shared/Text';
import { conditionallyAddParantheses } from '@/helpers/formatHelpers';

interface Props {
  heading: string;
  headingValue: string;
  loading?: boolean;
  positionType: PositionTypes;
  rows: DashboardDetailRowProps[];
}

const DashboardDetailSection: React.FC<Props> = ({ heading, headingValue, loading, positionType, rows }: Props) => {
  const columnWidths = useBreakpointValue({ base: [2.1, 1, 1], md: [2.5, 1, 1] }) ?? [2.5, 1, 1];

  return (
    <Container px={0} pb={0}>
      <Flex justifyContent="space-between" mb="6" px={6}>
        <Text fontWeight="600" fontSize="xl">
          {heading}
        </Text>
        <Skeleton isLoaded={!loading}>
          <Heading fontWeight="800" fontSize="2xl">
            {conditionallyAddParantheses(headingValue, positionType)}
          </Heading>
        </Skeleton>
      </Flex>

      <DashboardSectionHeader columnWidths={columnWidths} />

      {rows.map((row, index) => (
        <DashboardDetailRow columnWidths={columnWidths} key={index} loading={loading} {...row} />
      ))}
    </Container>
  );
};
export default DashboardDetailSection;

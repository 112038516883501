import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { Position, PositionTypes } from '@/types';
import { conditionallyAddParantheses, formatPercentage, formatUsdAmount } from '@/helpers/formatHelpers';

import DashboardPositionRow from './DashboardPositionRow';
import Divider from '../shared/Divider';
import React from 'react';
import Skeleton from '../shared/Skeleton';
import Text from '@/components/shared/Text';

export interface DashboardDetailRowProps {
  columnWidths?: number[];
  interestPercentage: number;
  loading?: boolean;
  name: string;
  positions: Position[];
  positionType: PositionTypes;
  value: number;
}

const DashboardDetailRow: React.FC<DashboardDetailRowProps> = ({
  columnWidths,
  interestPercentage,
  loading,
  name,
  positions,
  positionType,
  value,
}: DashboardDetailRowProps) => {
  const theme = useTheme();

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const formattedUsdAmount = formatUsdAmount(value);

  const rowHasPositions = positions?.length > 0;

  const headerContent = (
    <Flex mt={6} px={6} pb={6} flex={1}>
      <Flex alignItems="center" flex={columnWidths[0]}>
        <Text fontWeight="400" fontSize={{ base: 'sm', md: 'lg' }}>
          {name}
        </Text>
        {value > 0 && rowHasPositions && <AccordionIcon ml={2} color="gray.300" />}
      </Flex>

      <Box flex={columnWidths[1]} textAlign="right">
        <Skeleton ml={8} isLoaded={!loading}>
          <Text
            fontWeight="600"
            fontSize={{ base: 'md', md: 'lg' }}
            color={choosePercentageColor(positionType, interestPercentage, theme.utilityColors)}
          >
            {formatPercentage(interestPercentage)}
          </Text>
        </Skeleton>
      </Box>

      <Box flex={columnWidths[2]} textAlign="right">
        <Skeleton ml={8} isLoaded={!loading}>
          <Text fontWeight="600" fontSize={{ base: 'md', md: 'lg' }}>
            {conditionallyAddParantheses(formattedUsdAmount, positionType)}
          </Text>
        </Skeleton>
      </Box>
    </Flex>
  );

  if (!rowHasPositions) {
    return (
      <Box borderTop="1px solid" borderTopColor="gray.100">
        {headerContent}
      </Box>
    );
  }

  return (
    <>
      <Divider />
      <Accordion index={isOpen ? [0] : []} allowToggle border="0px" borderColor="transparent" m={0} p={0}>
        <AccordionItem>
          <AccordionButton _hover={{ backgroundColor: 'gray.50' }} onClick={onToggle} m={0} p={0}>
            {headerContent}
          </AccordionButton>

          <AccordionPanel p={0}>
            {positions?.map((position, index) => (
              <DashboardPositionRow
                columnWidths={columnWidths}
                lastItem={index + 1 == positions.length}
                key={position.id}
                position={position}
                positionType={positionType}
              />
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

const choosePercentageColor = (positionType: PositionTypes, interestPercentage, { errorColor, successColor }) => {
  if (interestPercentage == 0) {
    return null;
  } else if (positionType === PositionTypes.Supply) {
    return successColor;
  } else if (positionType === PositionTypes.Invest) {
    return successColor;
  } else if (positionType === PositionTypes.Borrow) {
    return errorColor;
  } else {
    return null;
  }
};

export default DashboardDetailRow;
